import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import {objectHistoryClassLabel} from '../../lib/localizedHistoryLabels';
import {CLASSES} from '../../constants/endpoints'
/**
 * @fero
 */

class ClassSelect extends React.PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    };

    render() {
        const {value, ...props} = this.props;
        const valueChecked = value != null ? value : null;
        return <Select
            {...props}
            options={Object.values(CLASSES).map(c => {
               return {
                   value: c,
                   label: objectHistoryClassLabel(c)
               }
            })}
            value={valueChecked}
        />;
    }

}

export default ClassSelect;