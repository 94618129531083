import React from 'react';
import Helmet from '../project/Helmet';
import HistoryParams from './history/HistoryParams'
import HistoryDisplay from './history/HistoryDisplay'
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import { t } from '@lingui/macro';

/**
 * @fero
 */

class HistoryPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`História`}
            />
            <TableWithFiltersLayout
                params={<HistoryParams/>}
                table={<HistoryDisplay/>}
            />
        </React.Fragment>;
    }

}

export default HistoryPage;