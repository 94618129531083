import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import MethodSelect from '../../project/MethodsSelect'
import ClassSelect from '../../project/ClassSelect'
import {getQueryParam, getQueryParamNumber, navigateToParametrized} from '../../../lib/url';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import DateRangePicker from '../../general/DateRangePicker';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import EmployeesSelect from '../../project/EmployeesSelect';

/**
 * @dusan
 */

class HistoryParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    createSetParam = (param) => (value) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.HISTORY_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
            [param]: value,
        });
    };

    render() {
        const {location} = this.props;
        const historyClass = getQueryParam(location, QUERY_PARAMS.HISTORY_CLASS);
        const historyMethod = getQueryParam(location, QUERY_PARAMS.HISTORY_METHOD);
        const historyWho = getQueryParamNumber(location, QUERY_PARAMS.HISTORY_WHO);
        const historyAt = getQueryParam(location, QUERY_PARAMS.HISTORY_AT);

        return <ColumnFilterLayout
            collapsible={false}
            columnClassName="history-params"
            filters={[ 
                [
                    {
                        label: <Trans>Objekt:</Trans>,
                        selector: <ClassSelect
                            className="full-size-width"
                            value={historyClass}
                            onChange={this.createSetParam(QUERY_PARAMS.HISTORY_CLASS)}
                        />
                    },
                    {
                        label: <Trans>Akcia:</Trans>,
                        selector: <MethodSelect
                            className="full-size-width"
                            value={historyMethod}
                            onChange={this.createSetParam(QUERY_PARAMS.HISTORY_METHOD)}
                            allowClear={true}
                        />
                    },
                ],
                [
                    {
                        label: <Trans>Vykonal:</Trans>,
                        selector: <EmployeesSelect
                            className="full-size-width"
                            value={historyWho}
                            onChange={this.createSetParam(QUERY_PARAMS.HISTORY_WHO)}
                            allowClear={true}
                        />
                    },
                    {
                        label: <Trans>Dátum:</Trans>,
                        selector: <DateRangePicker
                            value={historyAt}
                            onChange={this.createSetParam(QUERY_PARAMS.HISTORY_AT)}
                            allowClear={true}
                        />
                    }
                ]
            ]}
        />;
    }
}

export default locationHOC(HistoryParams);