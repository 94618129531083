import React from 'react';
import HistoryModal from '../../history/HistoryModal'
import {ObjectHistoryMethod} from '../../../constants/propTypesDefinitions'
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class HistoryModalAction extends React.PureComponent {
    static propTypes = {
        data: ObjectHistoryMethod,
    };

    render() {
        const {data} = this.props;
        return <HistoryModal
            objectId={data.id_obj}
            objectType={data.class}
            size="small"
            useTooltip={false}
            buttonLabel={<Trans>História objektu</Trans>}
        />
    }

}

export default HistoryModalAction;